<template>
  <v-toolbar color="white">
    <v-toolbar-items class="align-end">
      <v-text-field
        label="search"
        v-model.trim="search"
        prepend-icon="search"
      >
      </v-text-field>
    </v-toolbar-items>

    <v-toolbar-items>
      <div class="pa-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="emitRefresh">
              <v-icon>
                refresh
              </v-icon>
            </v-btn>
          </template>
          <span>
          Refresh
        </span>
        </v-tooltip>
      </div>
    </v-toolbar-items>

    <v-spacer></v-spacer>

    <v-toolbar-items>
      <div class="select d-flex">
        <v-autocomplete
          class="align-end"
          :items="typology"
          label="Typology"
          v-model="selectedTypology"
          clearable
        ></v-autocomplete>
      </div>
    </v-toolbar-items>

    <v-toolbar-items>
      <div class="select d-flex">
        <v-autocomplete
          class="align-end"
          :items="sale"
          label="Sales in charge"
          v-model="selectedSale"
          clearable
        >
          <template v-slot:selection="data">
            {{ getNameOfCollaborator(data.item) }}
          </template>
          <template v-slot:item="data">
            {{ getNameOfCollaborator(data.item) }}
          </template>
        </v-autocomplete>
      </div>
    </v-toolbar-items>

    <v-toolbar-items>
      <div class="select d-flex">
        <v-autocomplete
          class="align-end"
          :items="ams"
          label="AM in charge"
          clearable
          v-model="selectedAM"
        >
          <template v-slot:selection="data">
            {{ getNameOfCollaborator(data.item) }}
          </template>
          <template v-slot:item="data">
            {{ getNameOfCollaborator(data.item) }}
          </template>
        </v-autocomplete>
      </div>
    </v-toolbar-items>

  </v-toolbar>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'BillingEntitiesToolbar',
  props: {
    value: {
      type: String,
      default: ''
    },
    ams: {
      type: Array,
      default: () => []
    },
    sale: {
      type: Array,
      default: () => []
    },
    typology: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      search: '',
      isMounted: false,
      selectedTypology: null,
      selectedAM: null,
      selectedSale: null
    }
  },
  mounted: function () {
    this.search = this.value
    this.getQueryParams()
    this.isMounted = true
  },
  methods: {
    emitRefresh () {
      this.$emit('refresh')
    },
    getQueryParams () {
      if (this.$route.query.search !== undefined) {
        this.search = this.$route.query.search
      }
    },
    setQueryParams (search) {
      // for avoid duplicate navigation error
      if (this.$route.query.search === search) {
        return
      }
      this.$router.push({ name: 'billingEntities', query: { search: search } })
    },
    getNameOfCollaborator (collaborator) {
      return this.$commonUtils.getCollaboratorNameFromMail(collaborator)
    }
  },
  watch: {
    value (val) {
      if (val !== this.search) {
        this.search = val
      }
    },
    search: _.debounce(function (val) {
      if (this.isMounted) {
        this.setQueryParams(val)
      }
      this.$store.commit('setSearchBilling', val)
      this.$emit('input', val)
    }, 600),
    selectedTypology (val) {
      if (val === undefined) val = ''
      this.$emit('typology', val)
    },
    selectedAM (val) {
      if (val === undefined) val = ''
      this.$emit('am', val)
    },
    selectedSale (val) {
      if (val === undefined) val = ''
      this.$emit('sale', val)
    }
  }
}
</script>

<style scoped>
.select {
  padding-right: 2em;
  size: auto;
}
</style>
