import { apiCaller, Vue } from '../index'

const state = {
  entities: [],
  loadingEntities: false,
  searchBilling: '',
  editBilling: false,
  editBillingID: 0
}

const actions = {
  async getApiBillingEntities ({ commit, state }, args) {
    commit('setLoadingEntities', true)

    let data = { search: state.searchBilling }

    const result = await apiCaller.getBillingEntities(data)

    if (apiCaller.isResponseError(result)) {
      commit('setErrorMessageWithResponse', result)
      commit('gotEntities', [])
    } else {
      commit('gotEntities', result.data)
    }
    commit('setLoadingEntities', false)
  }
}

const mutations = {
  gotEntities (state, entities) {
    Vue.set(state, 'entities', entities)
  },
  setLoadingEntities (state, loading) {
    Vue.set(state, 'loadingEntities', loading)
  },
  setSearchBilling (state, search) {
    Vue.set(state, 'searchBilling', search)
  },
  setEditBilling (state, edit) {
    Vue.set(state, 'editBilling', edit)
  },
  setEditBillingID (state, id) {
    Vue.set(state, 'editBillingID', id)
  },
  resetEditBilling (state) {
    Vue.set(state, 'editBilling', false)
    Vue.set(state, 'editBillingID', 0)
  }
}

const getters = {
  getEntities (state) {
    return state.entities
  },
  getLoadingEntities (state) {
    return state.loadingEntities
  },
  getSearchBilling (state) {
    return state.searchBilling
  },
  getEditBilling (state) {
    return state.editBilling
  },
  getEditBillingID (state) {
    return state.editBillingID
  }
}

export const billingEntities = {
  actions,
  mutations,
  state,
  getters
}
