<template>
  <v-card>
    <v-toolbar
      color="white"
      tabs
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Billing Entities</v-toolbar-title>

    </v-toolbar>

    <BillingEntitiesToolbar
      v-model="search"
      v-on:refresh="refresh"
      :ams="filterAmList"
      :sale="filterSaleList"
      :typology="filterTypologyList"
      @am="getFilterAm"
      @sale="getFilterSale"
      @typology="getFilterTypology"
    >
    </BillingEntitiesToolbar>

    <div>
      <v-data-table
        :loading="$store.getters.getLoadingEntities"
        :items="filteredEntities"
        :search="search"
        :options.sync="pagination"
        :headers="headers"
        :footer-props="{
          itemsPerPageOptions: rowPerPageItem
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td>
              {{ props.item.id }}
            </td>

            <td>
              <strong>
                {{ props.item.name }}
              </strong>
            </td>

            <td>
              {{props.item.nickname}}
            </td>

            <td>
              <div v-if="hasCompanyOrHolding(props.item)">
                <v-icon small>
                  launch
                </v-icon>
                <a @click="redirectToCompany(props.item)" >
                {{getCompanyOrHoldingName(props.item)}}
              </a>
              </div>
              <span v-else> - </span>
            </td>

            <td>
              {{props.item.typology}}
            </td>

            <td>
              {{ getSaleInChargeNameToDisplay(props.item.sale_in_charge) }}
            </td>
            <td class="am-list align-center">
              <v-row no-gutters>
                <v-col v-for="am in getAmNamesList(props.item.am)" :key="am">
                  {{ am }}
                </v-col>
              </v-row>
            </td>
            <td>
              <v-btn icon :disabled="!hasCompanyOrHolding(props.item)">
                <v-icon @click="editBilling(props.item)">
                  edit
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>
    </div>

  </v-card>
</template>

<script>

import BillingEntitiesToolbar from '@/components/BillingEntities/BillingEntitiesToolbar'
import store from '../../store'
import { billingEntities } from '../../store/modules/billingEntities'
import { ROW_PER_PAGE_ITEM } from '@/components/KeystoneV2/commonConstantKeystone'
import { capitalize } from 'lodash'
import { usersMixin } from '@/mixins/usersMixin'
import baseViewMixin from '@/mixins/baseViewMixin'

// load the store module dynamically only when needed
if (!store.state.billingEntities) store.registerModule('billingEntities', billingEntities)

export default {
  name: 'BillingEntities',
  mixins: [usersMixin, baseViewMixin],
  components: {
    BillingEntitiesToolbar
  },
  data: function () {
    return {
      headers: [
        { text: 'Internal ID', value: 'id', width: '15px', sortable: false },
        { text: 'Billing entity name', value: 'name' },
        { text: 'Billing entity nickname', value: 'nickname' },
        { text: 'Company / Holding', sortable: false, filterable: false },
        { text: 'Typology', value: 'typology', filterable: false },
        { text: 'Sales in charge', value: 'sale_in_charge' },
        { text: 'AM in charge', value: 'am', sortable: true },
        { text: 'Action', sortable: false }
      ],
      pagination: {},
      disabledAutoSearch: false,
      rowPerPageItem: ROW_PER_PAGE_ITEM,
      search: '',
      filterTypology: '',
      filterSale: '',
      filterAm: ''
    }
  },
  async mounted () {
    this.onMounted()
    await this.callApi()
  },
  computed: {
    entities: function () {
      return this.$store.getters.getEntities
    },
    filteredEntities: function () {
      if (this.filterTypology === '' && this.filterAm === '' && this.filterSale === '') return this.entities

      let res = this.entities
      if (this.filterTypology !== '') res = res.filter(item => item.typology === this.filterTypology)
      if (this.filterSale !== '') res = res.filter(item => item.sale_in_charge === this.filterSale)
      if (this.filterAm !== '') res = res.filter(item => item.am.includes(this.filterAm))

      return res
    },
    filterTypologyList: function () {
      let res = []
      for (const entity of this.entities) {
        if (entity.typology === null) entity.typology = ''
        if (!res.includes(entity.typology) && entity.typology !== '') res.push(entity.typology)
      }
      return res
    },
    filterSaleList: function () {
      let res = []
      for (const entity of this.entities) {
        const sale = this.getSaleInChargeName(entity.sale_in_charge)
        if (!res.includes(sale) && sale !== '') res.push(sale)
      }
      return res
    },
    filterAmList: function () {
      let res = []
      for (const entity of this.entities) {
        let split = entity.am.split(',')

        for (const am of split) {
          if (!res.includes(am) && am !== '') res.push(am)
        }
      }
      return res
    }
  },
  watch: {
    '$store.getters.getSearchBilling': {
      handler: async function (val) {
        console.log(val)
        this.pagination.page = 1
        await this.callApi()
      }
    }
  },
  methods: {
    async refresh () {
      if (this.$store.getters.getLoadingEntities) {
        return
      }
      await this.callApi()
    },
    async callApi () {
      await this.$store.dispatch('getApiBillingEntities')
    },
    getSaleInChargeName (sale) {
      return sale !== null ? sale : ''
    },
    getSaleInChargeNameToDisplay (sale) {
      // console.log('in getSaleInChargeNameToDisplay', sale)
      return sale !== null ? this.getCollaboratorNameFromMail(sale) : ''
    },
    getFilterTypology (value) {
      this.filterTypology = value
    },
    getFilterAm (value) {
      this.filterAm = value
    },
    getFilterSale (value) {
      this.filterSale = value
    },
    hasCompanyOrHolding (item) {
      if (item === null || (item.mh_id === null && item.h_id === null && item.c_id === null)) return false
      return true
    },
    getCompanyOrHoldingName (item) {
      if (item.mh_id !== null) return 'MetaHolding - ' + item.mh_name
      else if (item.h_id !== null) return 'Holding - ' + item.h_name
      else if (item.c_id !== null) return 'Company - ' + item.c_name
    },
    getCompanyOrHoldingLink (item) {
      if (item === null) return null
      let query = { type: '', search: '' }
      if (item.mh_id !== null) {
        query.type = 'metaholding'
        query.search = item.mh_name
      } else if (item.h_id !== null) {
        query.type = 'holding'
        query.search = item.h_name
      } else if (item.c_id !== null) {
        query.type = 'company'
        query.search = item.c_name
      } else return null
      return {
        name: 'keystoneV2',
        query
      }
    },
    redirectToCompany (item) {
      const route = this.getCompanyOrHoldingLink(item)
      this.$router.push(route)
    },
    editBilling (item) {
      let route = this.getCompanyOrHoldingLink(item)
      if (route === null) return
      this.$store.commit('setEditBilling', true)
      switch (route.query.type) {
        case 'metaholding':
          this.$store.commit('setEditBillingID', item.mh_id)
          break
        case 'holding':
          this.$store.commit('setEditBillingID', item.h_id)
          break
        case 'company':
          this.$store.commit('setEditBillingID', item.c_id)
          break
      }
      this.$router.push(route)
    },
    getAmNamesList (ams) {
      let split = ams.split(',')

      let uniqueNames = []
      for (const am of split) {
        const name = this.getCollaboratorNameFromMail(am)
        if (!uniqueNames.includes(name) && name !== '') {
          uniqueNames.push(name)
        }
        if (!this.filterAmList.includes(name) && name !== '') {
          this.filterAmList.push(name)
        }
      }
      return uniqueNames
    },
    getCollaboratorNameFromMail (collaborator) {
      return this.$commonUtils.getCollaboratorNameFromMail(collaborator)
    }
  }
}
</script>

<style scoped>
.am-list {
  display: table-cell;
  vertical-align: baseline;
  align-items: center;
  /* display: inline-block; */
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
